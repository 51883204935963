import React, { Component } from 'react'

import Layout from '../Components/layout.js'
import SEO from '../Components/seo.js'
import Row from '../Components/Rows.jsx'
import Picture from '../Components/Picture.jsx'
import TextTitle from '../Components/TextTitle.jsx'
import TextTiles from '../Components/TextTiles.jsx'

class Materials extends Component {
  render() {
    return (
      <Layout>
        <SEO title={'Services'}/>
        <Picture splash src='MaterialSplash2'/>
        <Row noPaddingBottom>
          <TextTitle title='Services'/>
        </Row>
        {/* <Row>
          <TextWithPicture
            below
            alignPicture={'left'}
            pictureSize={'equal'}
            imgSrc={'Splash9'}
            title={'Design'}
            textContent={'As required by Federal law, all textile products must have a label listing: a) Fiber content,b) Country of origin (COO),c) Care instructionsd) Identity of the manufacturer https://pcblabel.com/federal-labeling-laws-faq/'}
          />
        </Row>

        <Row>
          <TextWithPicture
            below
            alignPicture={'left'}
            pictureSize={'equal'}
            imgSrc={'Splash8'}
            title={'Federal Labeling Laws'}
            textContent={'As required by Federal law, all textile products must have a label listing: a) Fiber content,b) Country of origin (COO),c) Care instructionsd) Identity of the manufacturer https://pcblabel.com/federal-labeling-laws-faq/'}
          />
        </Row> */}

        <Row>
          <TextTiles
            tiles={[
              {
                title: 'Design',
                content: 'Our inhouse art department has the experience to help you create the label you desire. We provide revisions and samples to help turn your ideas into reality. '
              },
              {
                title: 'Consultation',
                content: 'In addition to providing labels, we can also help you setup your own in house printing production. Please contact us if you would like a demonstration of our label printing technologies.'
              }
            ]}
            cols={2}
          />
        </Row>


        <Row noPadding>
          <TextTitle title='Printing Methods' />
        </Row>

        <Row>
          <TextTiles
            tiles={[
              {
                title: 'Flexo',
                content: 'Flexographic printing, often called flexo, is a efficient printing process that transfers ink via a plate onto the desired material. It has a higher initial cost but a lower run cost and is great for large quantity orders.'
              },
              {
                title: 'Thermal Transfer',
                content: 'Thermal transfer is a fast and reliable way of printing small quantity orders. The ink comes in a roll that is sandwiched between the material and a printhead. A computer-rendered image is sent to the printer that heats the ink causing it to melt and adhere to the material line-by-line.'
              }
            ]}
            cols={2}
          />
        </Row>
      </Layout>
    )
  }
}

export default Materials